import DashboardIcon from '@mui/icons-material/Dashboard';
import ChatIcon from '@mui/icons-material/Chat';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import BusinessIcon from '@mui/icons-material/Business';
import GroupIcon from '@mui/icons-material/Group';
import CampaignIcon from '@mui/icons-material/Campaign';
import SettingsIcon from '@mui/icons-material/Settings';
import { Brand } from '../../types';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import {
  RiFileSettingsLine,
  RiAdvertisementLine,
  RiSettings5Line,
} from 'react-icons/ri';
import { FaArrowTrendUp } from 'react-icons/fa6';
import { AnalyticTools, BusinessType } from '../../utils/helpers/BrandHelpers';
import { TbReportAnalytics } from 'react-icons/tb';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { HiOutlineUsers } from 'react-icons/hi2';
import { CgTemplate } from 'react-icons/cg';
import { SlGraph } from 'react-icons/sl';
import { AiOutlineTool } from 'react-icons/ai';
import { LuFolderTree } from 'react-icons/lu';
import { MdOutlineCampaign } from 'react-icons/md';
import { RiMoneyDollarCircleLine } from 'react-icons/ri';
import { UserCapability } from '../../context/authContext';
import { LiaFunnelDollarSolid } from 'react-icons/lia';
import { CrmSetting } from '../../types/ICrm';

export const drawers = (
  brand: Brand,
  isAdmin: boolean,
  isAgency: boolean,
  isFranchisee: boolean,
) => {
  let temp = [
    {
      text: 'Dashboard',
      icon: <DashboardIcon />,
      link: '/dashboard',
      pathName: 'dashboard',
    },
    {
      text: 'Reports',
      icon: <AutoGraphIcon />,
      link: '/reports',
      pathName: 'reports',
    },
  ];

  if (isAdmin || isAgency) {
    temp = [
      ...temp,
      {
        text: 'Budgets',
        icon: <LocalAtmIcon />,
        link: '/budgets',
        pathName: 'budgets',
      },
    ];
  }

  if (brand?.page?._id) {
    temp = [
      ...temp,
      {
        text: 'Chatz',
        icon: <ChatIcon />,
        link: '/chats',
        pathName: 'chats',
      },
    ];
  }

  if (
    !isAgency &&
    (brand?.allowSetupPayment || brand?.allowSetupDepositAccount)
  ) {
    temp = [
      ...temp,
      {
        text: 'Billing',
        icon: <CreditCardIcon />,
        link: '/billings',
        pathName: 'billings',
      },
    ];
  }

  if (isAdmin) {
    temp = [
      ...temp,
      {
        text: 'Campaigns',
        icon: <CampaignIcon />,
        link: '/campaigns',
        pathName: 'campaigns',
      },
      {
        text: 'Agencies',
        icon: <BusinessIcon />,
        link: '/agencies',
        pathName: 'agencies',
      },
      {
        text: 'Users',
        icon: <GroupIcon />,
        link: '/users',
        pathName: 'users',
      },
    ];
  }

  if (
    isAdmin ||
    (!isAdmin &&
      ((brand?.__type === 'location' && !isFranchisee) ||
        (isFranchisee && brand?.__type === 'location' && brand?.page?._id) ||
        (brand?.__type === 'brand' && brand?.page?._id)))
  ) {
    temp = [
      ...temp,
      {
        text: 'Preferences',
        icon: <SettingsIcon />,
        link: '/preferences',
        pathName: 'preferences',
      },
    ];
  }

  return temp;
};

export const diyAdzDrawers = (
  brand: Brand,
  isFacebook: boolean,
  crmSettings: CrmSetting,
  isAdmin?: boolean,
  capabilities?: UserCapability,
  withAgencyAccess?: boolean,
) => {
  let temp: any[] = [];
  const isFrandev: boolean = brand?.businessType === BusinessType.FRANDEV;

  if (brand?.analyticTools?.includes(AnalyticTools.SCORECARDZ)) {
    temp = [
      ...temp,
      {
        text: 'Scorecardz',
        icon: <SlGraph size={25} />,
        link: '/scorecardz',
        pathName: 'scorecardz',
      },
    ];
  }

  if (brand?.analyticTools?.includes(AnalyticTools.TRENDZ)) {
    temp = [
      ...temp,
      {
        text: 'Trendz',
        icon: <FaArrowTrendUp size={25} />,
        link: '/trendz',
        pathName: 'trendz',
      },
    ];
  }

  if (isAdmin || capabilities?.viewReports) {
    temp = [
      ...temp,
      {
        text: 'Reportz',
        icon: <TbReportAnalytics size={25} />,
        link: '/reportz',
        pathName: 'reportz',
      },
    ];
  }

  if (
    crmSettings?.providers?.includes('client-tether') &&
    crmSettings?.clientTetherSettings?.accessToken &&
    crmSettings?.clientTetherSettings?.webKey &&
    crmSettings?.clientTetherSettings?.salesCycles?.length > 0
  ) {
    temp = [
      ...temp,
      {
        text: 'Pipelinez',
        icon: <LiaFunnelDollarSolid size={25} />,
        link: '/pipelinez',
        pathName: 'pipelinez',
      },
    ];
  }

  if (isFacebook) {
    temp = [
      ...temp,
      {
        text: 'Leadz',
        icon: <GroupAddOutlinedIcon sx={{ fontSize: '25px' }} />,
        link: '/leadz',
        pathName: 'leadz',
      },
    ];
  }

  if (
    ((isAdmin && isFrandev) || (capabilities?.viewReports && isFrandev)) &&
    isFacebook
  ) {
    temp = [
      ...temp,
      {
        text: 'AI Toolz',
        icon: <AiOutlineTool size={25} />,
        link: '',
        pathName: '',
        collapsible: true,
        items: [
          {
            text: 'AI Insightz',
            icon: <RiFileSettingsLine size={25} />,
            link: '/ai/insightz',
            pathName: 'ai',
          },
          /* TODO: Re-enable if necessary
          {
            text: 'Ask Z',
            icon: <RiQuestionnaireLine size={25} />,
            link: '/ask-z',
            pathName: 'ask-z',
          },
          */
        ],
      },
    ];
  }

  if ((isAdmin || capabilities?.createTemplate) && isFacebook) {
    temp = [
      ...temp,
      {
        text: 'Templatez',
        icon: <CgTemplate size={25} />,
        link: '/templatez',
        pathName: 'templatez',
      },
    ];
  }

  if ((isAdmin || capabilities?.createAds) && isFacebook) {
    temp = [
      ...temp,
      {
        text: 'Adz',
        icon: <RiAdvertisementLine size={25} />,
        link: '/adz',
        pathName: 'adz',
      },
    ];
  }

  if (isAdmin) {
    temp = [
      ...temp,
      {
        text: 'Campaignz',
        icon: <MdOutlineCampaign size={25} />,
        link: '/campaignz',
        pathName: 'campaignz',
      },
    ];
  }

  if (isAdmin || (!isAdmin && capabilities?.viewBudgets)) {
    temp = [
      ...temp,
      {
        text: 'Budgetz',
        icon: <RiMoneyDollarCircleLine size={25} />,
        link: '/budgetz',
        pathName: 'budgetz',
      },
    ];
  }

  if ((isAdmin || (!isAdmin && capabilities?.createAds)) && isFacebook) {
    temp = [
      ...temp,
      {
        text: 'Assetz',
        icon: <LuFolderTree size={25} />,
        link: '/assetz',
        pathName: 'assetz',
      },
    ];
  }

  if (isAdmin) {
    temp = [
      ...temp,
      {
        text: 'Userz',
        icon: <HiOutlineUsers size={25} />,
        link: '/userz',
        pathName: 'userz',
      },
    ];
  }

  if (brand?.allowSetupPayment || brand?.allowSetupDepositAccount) {
    temp = [
      ...temp,
      {
        text: 'Billingz',
        icon: <CreditCardIcon />,
        link: '/billingz',
        pathName: 'billingz',
      },
    ];
  }

  if (isAdmin || (!isAdmin && withAgencyAccess && capabilities.agencyAdmin)) {
    temp = [
      ...temp,
      {
        text: 'Agenciez',
        icon: <BusinessIcon />,
        link: '/agenciez',
        pathName: 'agenciez',
      },
    ];
  }

  if (
    isAdmin ||
    capabilities?.createAds ||
    capabilities?.createTemplate ||
    capabilities?.addUser ||
    capabilities?.createBrands ||
    brand?.__type === 'location'
  ) {
    temp = [
      ...temp,
      {
        text: 'Preferencez',
        icon: <RiSettings5Line size={25} />,
        link: '/preferencez',
        pathName: 'preferencez',
      },
    ];
  }

  return temp;
};
