export const googleEnums = {
  keywords: ['UNSPECIFIED', 'UNKNOWN', 'EXACT', 'PHRASE', 'BROAD'],
  advertisingChannel: [
    'UNSPECIFIED',
    'UNKNOWN',
    'SEARCH',
    'DISPLAY',
    'SHOPPING',
    'HOTEL',
    'VIDEO',
    'MULTI CHANNEL',
    'LOCAL',
    'SMART',
    'PERFORMANCE MAX',
    'LOCAL SERVICES',
    'DISCOVERY',
    'TRAVEL',
  ],
  campaignStatus: ['UNSPECIFIED', 'UNKNOWN', 'ENABLED', 'PAUSED', 'REMOVED'],
};

export const GoogleCampaignStatus = {
  UNSPECIFIED: 'UNSPECIFIED',
  UNKNOWN: 'UNKNOWN',
  ENABLED: 'ENABLED',
  PAUSED: 'PAUSED',
  REMOVED: 'REMOVED',
};

export const googleHelpers = {
  micros: (value: number) => {
    if (!value || value === 0) {
      return 0;
    }

    return value / 1000000;
  },
};
