import React, { useContext, useEffect, useState } from 'react';
import styles from '../../assets/styles/pages/Pages.module.scss';
import PageHeader from '../../components/PageHeader';
import { PrimaryButton } from '../../components/Buttons';
import { useNavigate } from 'react-router-dom';
import { AdsTemplate } from '../../types/IDiy';
import { Brand } from '../../types';
import { useSelector } from 'react-redux';
import {
  archiveCampaignTemplate,
  fetchCampaignTemplates,
} from '../../services/diy';
import { adsProvider } from '../../utils/constants/facebookAds';
import AdsTemplateListTable from '../../components/Tables/TemplateList/AdsTemplateListTable';
import { DrawerMenu } from '../../components/Menu';
import { AuthContext } from '../../context';
import CircularLoading from '../../components/CircularLoading';
import FacebookAdDetails from '../../components/Details/Ads/FacebookAdDetails';
import { useMediaQuery, useTheme } from '@mui/material';

const Templates: React.FC = () => {
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const navigate = useNavigate();
  useContext(AuthContext);

  const [templates, setTemplates] = useState<AdsTemplate[]>([]);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<AdsTemplate>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmArchive, setConfirmArchive] = useState<boolean>(false);

  useEffect(() => {
    if (brand)
      getTemplates(brand?.__type === 'location' ? brand?.franchisor : brand);
  }, [brand]);

  const getTemplates = async (brand: Brand) => {
    setFetchLoading(true);
    try {
      const response = await fetchCampaignTemplates(
        brand?._id,
        adsProvider.FACEBOOK,
        true,
      );

      setTemplates(response.data);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setFetchLoading(false);
    }
  };

  const handleOpenPreview = (template: AdsTemplate) => {
    setSelectedTemplate(template);
    setOpenPreview(true);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
    setConfirmArchive(false);
  };

  const handleArchiveTemplate = async () => {
    setLoading(true);
    try {
      const response = await archiveCampaignTemplate(selectedTemplate?._id);

      const temp = templates?.filter(
        (template: AdsTemplate) => template?._id !== response?.data?._id,
      );

      setTemplates(temp);
      handleClosePreview();
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.page}>
      <CircularLoading loading={loading} />

      <div className={styles.base}>
        <div className={styles.header}>
          <PageHeader title="Templatez" />

          <div className={styles.controls}>
            <div className={styles.buttons}>
              <PrimaryButton
                title="Create Template"
                type="button"
                handleOnClick={() => navigate('/templatez/create')}
              />
            </div>
          </div>
        </div>

        <div>
          <AdsTemplateListTable
            templates={templates}
            loading={fetchLoading}
            onOpenPreview={handleOpenPreview}
          />
        </div>
      </div>

      <DrawerMenu
        anchor="right"
        open={openPreview}
        onClose={handleClosePreview}
      >
        <FacebookAdDetails
          brand={brand?.__type === 'location' ? brand?.franchisor : brand}
          templateId={selectedTemplate?._id}
          isTemplate
          withAction
          onArchive={handleArchiveTemplate}
          archiveLoading={loading}
          onClose={handleClosePreview}
          isMobile={xsOnly}
          confirmArchive={confirmArchive}
          setConfirmArchive={setConfirmArchive}
        />
      </DrawerMenu>
    </div>
  );
};

export default Templates;
