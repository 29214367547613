import React, { useReducer, createContext, Context, ReactNode } from "react";
import { EDIT_THEME, GET_THEME } from "../utils";

interface Theme {
  theme: string;
  properties: any;
}

interface State {
  expressTheme: Theme;
}
// action type
interface Action {
  type: string;
  payload?: any;
}
interface ProviderProps {
  children: ReactNode;
}
// state
const initialState: State = {
  expressTheme: {
    theme: "",
    properties: {},
  },
};

interface ContextValueType {
  state: State;
  dispatch: React.Dispatch<Action>;
  // loading: boolean
}

// reducer
const themeReducer = (state: State, action: Action) => {
  switch (action.type) {
    case GET_THEME:
      return {
        ...state,
        expressTheme: action.payload,
      };
    case EDIT_THEME:
      return {
        ...state,
        expressTheme: action.payload,
      };
    default:
      return state;
  }
};

// create context
export const ExpressThemeContext: Context<ContextValueType> = createContext(
  {} as ContextValueType
);

// create provider
const ExpressThemeProvider: React.FC<ProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(themeReducer, initialState);
  const value: ContextValueType = { state, dispatch };

  return (
    <ExpressThemeContext.Provider value={value}>
      {children}
    </ExpressThemeContext.Provider>
  );
};

export default ExpressThemeProvider;
