import React from 'react';
import styles from '../../assets/styles/pages/Pages.module.scss';
import PageHeader from '../../components/PageHeader';
import { useSelector } from 'react-redux';
import { adsProvider } from '../../utils/constants/facebookAds';
import FacebookAssetzForm from '../../components/Forms/Assetz/Facebook/FacebookAssetzForm';

const Assetz: React.FC = () => {
  const campaignProvider = useSelector(
    (state: any) => state?.campaignProvider?.campaignProvider,
  );

  return (
    <div className={styles.page}>
      <div className={styles.base}>
        <div className={styles.header}>
          <PageHeader title="Assetz" />
        </div>

        {campaignProvider === adsProvider.FACEBOOK ? (
          <FacebookAssetzForm />
        ) : null}
      </div>
    </div>
  );
};

export default Assetz;
