import { Box, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CampaignAd } from '../../../types';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { adsTableColumns, adsTableGroupingColumn } from './AdListTableColumns';
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridGroupNode,
} from '@mui/x-data-grid-premium';
import { CustomLoadingOverlay } from '../../Loading';
import { useSelector } from 'react-redux';
import { FbAdStatus } from '../../../utils/helpers/facebookAdsHelper';
import { arrayUniqueFilter } from '../../../utils/arrayFormatter';

interface AdListTableProps {
  ads: CampaignAd[];
  loading: boolean;
  onOpenPreview?: (id: string, level: string, objective: string) => void;
}

const AdListTable: React.FC<AdListTableProps> = ({
  ads,
  loading,
  onOpenPreview,
}) => {
  const theme = useTheme();
  const isXsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const timezone = useSelector((state: any) => state?.brandTimezone?.timezone);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

  useEffect(() => {
    if (ads.length > 0) {
      const filteredAds = ads.filter(
        (ad: CampaignAd) => ad.status === FbAdStatus.ACTIVE,
      );

      const groups = filteredAds.map((ad: CampaignAd) => ad.group).flat();

      const filteredKeys = arrayUniqueFilter(groups);
      setExpandedKeys(filteredKeys);
    }
  }, [ads]);

  const getTreeDataPath: DataGridPremiumProps['getTreeDataPath'] = (row) =>
    row.group;

  const rows = ads.slice().map((ad: CampaignAd) => {
    return {
      ...ad,
      onOpenPreview,
    };
  });

  const cellBgFormatter = (params: any) => {
    if (params.row.level === 'campaign') {
      return 'campaign';
    }

    if (params.row.level === 'adset') {
      return 'adset';
    }

    return '';
  };

  return (
    <Box
      sx={{
        '& .campaign': {
          backgroundColor: '#B6c61a',
        },
        '& .adset': {
          backgroundColor: '#b9d870',
        },
      }}
    >
      <DataGridPremium
        initialState={{
          sorting: {
            sortModel: [
              { field: 'duration', sort: 'desc' },
              { field: 'status', sort: 'asc' },
            ],
          },
        }}
        treeData
        getTreeDataPath={getTreeDataPath}
        className={styles.table}
        density="compact"
        rows={rows}
        groupingColDef={adsTableGroupingColumn(
          isXsOnly,
          onOpenPreview,
          expandedKeys,
          setExpandedKeys,
          ads,
        )}
        columns={adsTableColumns(timezone)}
        autoHeight
        hideFooterSelectedRowCount
        slots={{ loadingOverlay: CustomLoadingOverlay }}
        loading={loading}
        isGroupExpandedByDefault={(row: GridGroupNode) => {
          return expandedKeys.includes(row.groupingKey as string);
        }}
        getCellClassName={(params: any) => {
          return cellBgFormatter(params);
        }}
        hideFooterRowCount
        hideFooter
      />
    </Box>
  );
};

export default AdListTable;
