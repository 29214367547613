import React from 'react';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import {
  DataGridPremium,
  DataGridPremiumProps,
} from '@mui/x-data-grid-premium';
import { CustomLoadingOverlay } from '../../Loading';
import {
  facebookCampaignReportColumns,
  facebookCampaignReportGroupingColumn,
} from './FacebookCampaignReportTableColumns';
import { BrandFacebookReportData } from '../../../types';

interface FacebookCampaignReportTableProps {
  reportData: BrandFacebookReportData[];
  isLeads?: boolean;
  onOpenPreview: (adId: string) => void;
  isFranDev?: boolean;
}

const FacebookCampaignReportTable: React.FC<
  FacebookCampaignReportTableProps
> = ({ isLeads = false, reportData, onOpenPreview, isFranDev = false }) => {
  const theme = useTheme();
  const isMdAndDown = useMediaQuery(theme.breakpoints.down('md'));

  const getTreeDataPath: DataGridPremiumProps['getTreeDataPath'] = (row) =>
    row.groups;

  const cellBgFormatter = (params: any) => {
    if (
      params.rowNode.type === 'group' &&
      (params.row.level === 'campaign' ||
        (params.rowNode.type === 'group' &&
          ['Region', 'Platform', 'DMA'].includes(params.rowNode.groupingKey)))
    ) {
      return 'top';
    }

    if (params.row.level === 'adset') {
      return 'adset';
    }

    return '';
  };

  const rows = reportData.map((data: BrandFacebookReportData) => {
    /** Set the Qualified Leads count to the Lead count if the QL
     *  is more than the Actual lead
     *  This will happen if there is a test lead that was generated.
     *  The platform cannot identify if the lead was a test lead.
     */
    const ql =
      data.qualifiedLeads > data.leads ? data.leads : data.qualifiedLeads;

    return {
      ...data,
      qualifiedLeads: ql,
      cpl: data.leads > 0 ? data.spend / data.leads : 0,
      cpm: data.impressions > 0 ? (data.spend / data.impressions) * 1000 : 0,
      cpql: ql > 0 ? data.spend / ql : 0,
      cpr: data.results > 0 ? data.spend / data.results : 0,
      cpc: data.clicks > 0 ? data.spend / data.clicks : 0,
    };
  });

  return (
    <Box
      sx={{
        '& .top': {
          backgroundColor: '#B6c61a',
        },
        '& .adset': {
          backgroundColor: '#b9d870',
        },
      }}
    >
      <DataGridPremium
        initialState={{
          sorting: {
            sortModel: [{ field: isLeads ? 'leads' : 'results', sort: 'desc' }],
          },
        }}
        treeData
        groupingColDef={facebookCampaignReportGroupingColumn(
          isMdAndDown,
          onOpenPreview,
        )}
        getTreeDataPath={getTreeDataPath}
        rows={rows}
        columns={facebookCampaignReportColumns(
          isLeads,
          onOpenPreview,
          isFranDev,
        )}
        autoHeight
        density="compact"
        hideFooterSelectedRowCount
        hideFooterRowCount
        hideFooter
        loading={false}
        className={styles.table}
        disableRowSelectionOnClick
        slots={{ loadingOverlay: CustomLoadingOverlay }}
        defaultGroupingExpansionDepth={-1}
        getRowId={(row) => row.id}
        getCellClassName={(params: any) => {
          return cellBgFormatter(params);
        }}
      />
    </Box>
  );
};

export default FacebookCampaignReportTable;
