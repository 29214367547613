import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box,
  Grid,
  IconButton,
  Tab,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import { DEFAULT_COLOR_THEME, IS_DIY_ADZ } from '../../../utils';
import {
  AdVideo,
  Brand,
  Campaign,
  CampaignAd,
  CampaignChangeLog,
  CustomAudience,
  FacebookAd,
  FbCampaignAdSetForm,
  FbCreativeForm,
  FBLeadGenData,
  LeadFormData,
  LeadNotification,
} from '../../../types';
import {
  fetchBrandCampaigns,
  fetchSingleFbAdAccountVideo,
  fetchSpecificFacebookGenForms,
  fetchSpecificFbAd,
  fetchSpecificFbAdset,
  generateAdsPreview,
  getAdsChangeLogs,
  getFbTargetingReachEstimate,
  getSingleBrandAd,
  getSingleFbAdCreative,
} from '../../../services/ads';
import {
  adsProvider,
  CreativeTemplate,
  Objectives,
} from '../../../utils/constants/facebookAds';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../../redux/actions';
import { errorMessageParser } from '../../../utils/helpers/ToastHelper';
import { AdsTemplate, DiySettings } from '../../../types/IDiy';
import {
  fetchSingleCampaignTemplate,
  getCustomAudiences,
  getSingleDiySetting,
} from '../../../services/diy';
import FacebookAdPreview from './FacebookAdPreview';
import { FbAdStatus } from '../../../utils/helpers/facebookAdsHelper';
import { PrimaryButton } from '../../Buttons';
import { useNavigate } from 'react-router-dom';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { GiReceiveMoney } from 'react-icons/gi';
import { AiOutlineStop } from 'react-icons/ai';
import FacebookAdTargets from './FacebookAdTargets';
import {
  getFormLeadNotification,
  getSingleLeadForms,
} from '../../../services/lead';
import LeadNotificationDetails from './LeadNotificationDetails';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArchiveIcon from '@mui/icons-material/Archive';
import { getGeolocationFields } from '../../../utils/helpers/DiyHelpers';
import FacebookConversionDetails from './FacebookConversionDetails';
import CloseIcon from '@mui/icons-material/Close';
import CampaignLogs from './CampaignLogs';

interface FacebookAdDetailsProps {
  brand: Brand;
  adId?: string | null;
  withAction?: boolean;
  stopLoading?: boolean;
  setOpenConfirmModal?: Dispatch<SetStateAction<boolean>>;
  confirmCredit?: boolean;
  onStopAd?: (adId: string, refund?: boolean) => void;
  setConfirmCredit?: Dispatch<SetStateAction<boolean>>;
  confirmStop?: boolean;
  setConfirmStop?: Dispatch<SetStateAction<boolean>>;
  isTemplate?: boolean;
  templateId?: string | null;
  isDiy?: boolean;
  onSelect?: (template: AdsTemplate) => void;
  confirmArchive?: boolean;
  setConfirmArchive?: Dispatch<SetStateAction<boolean>>;
  onArchive?: () => void;
  archiveLoading?: boolean;
  onClose: () => void;
  isMobile: boolean;
  fetchLoading?: boolean;
  level?: string;
  campaignId?: string | null;
  adsetId?: string | null;
}

const FacebookAdDetails: React.FC<FacebookAdDetailsProps> = ({
  brand,
  adId = null,
  withAction = false,
  stopLoading = false,
  setOpenConfirmModal,
  confirmCredit = false,
  onStopAd,
  setConfirmCredit,
  confirmStop = false,
  setConfirmStop,
  isTemplate = false,
  templateId = null,
  isDiy = false,
  onSelect,
  confirmArchive = false,
  setConfirmArchive,
  onArchive,
  archiveLoading = false,
  onClose,
  isMobile,
  fetchLoading = false,
  level = 'ad',
  campaignId = null,
  adsetId = null,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const [tabValue, setTabValue] = useState<string>('1');
  const [tabLabel, setTabLabel] = useState<string>(null);
  const [fbAd, setFbAd] = useState<FacebookAd>(null);
  const [fbAdset, setFbAdset] = useState<FbCampaignAdSetForm>(null);
  const [fbPreview, setFbPreview] = useState<string>('');
  const [diySettings, setDiySettings] = useState<DiySettings>(null);
  const [ad, setAd] = useState<CampaignAd>(null);
  const [template, setTemplate] = useState<AdsTemplate>(null);
  const [adVideo, setAdVideo] = useState<AdVideo>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [creative, setCreative] = useState<FbCreativeForm>(null);
  const [fbForm, setFbForm] = useState<FBLeadGenData>(null);
  const [form, setForm] = useState<LeadFormData>(null);
  const [notification, setNotification] = useState<LeadNotification>(null);
  const [customAudiences, setCustomAudiences] = useState<CustomAudience[]>([]);
  const [fbAdLoading, setFbAdLoading] = useState<boolean>(false);
  const [audienceSize, setAudienceSize] = useState<any>({
    users_lower_bound: 0,
    users_upper_bound: 0,
  });
  const [audienceSizeLoading, setAudienceSizeLoading] =
    useState<boolean>(false);
  const [previewLoading, setPreviewLoading] = useState<boolean>(false);
  const [audienceSizeError, setAudienceSizeError] = useState<string>(null);
  const [logs, setLogs] = useState<CampaignChangeLog[]>([]);
  const [logsLoading, setLogsLoading] = useState<boolean>(false);

  useEffect(() => {
    const selectedTab = buildTabs(
      isTemplate
        ? template?.campaignTemplate?.objective
        : fbAd?.campaign?.objective,
    )[parseInt(tabValue) - 1];

    setTabLabel(selectedTab);
  }, [tabValue]);

  useEffect(() => {
    if (brand) getDiySetting();

    if (brand && isTemplate) getFbCustomAudiences();
  }, [brand, isTemplate]);

  useEffect(() => {
    if (isTemplate && templateId) {
      getTemplate();
    }
  }, [isTemplate, templateId]);

  useEffect(() => {
    if (brand && adId && !isTemplate) {
      getFbAd();
      getBrandAd();
    }
  }, [brand, adId, isTemplate]);

  useEffect(() => {
    if (tabLabel === 'Preview' && fbAd && !IS_DIY_ADZ && !isTemplate) {
      generatePreview();
    }
  }, [tabLabel, fbAd, IS_DIY_ADZ, isTemplate]);

  useEffect(() => {
    if (tabLabel === 'Preview' && IS_DIY_ADZ && !isTemplate && ad) {
      getTemplate();
    }
  }, [tabLabel, IS_DIY_ADZ, isTemplate, ad]);

  useEffect(() => {
    if (tabLabel === 'Targets' && fbAd && !isTemplate) {
      getFbAdset();
    }

    if (tabLabel === 'Targets' && diySettings && isTemplate && template) {
      let targeting: any = { ...template?.adSetTemplate?.targeting };
      const geolocationTypes = diySettings?.locationTypes?.map(
        (type: string) => type,
      );

      let geolocation: any = {};

      if (diySettings?.geolocation) {
        geolocationTypes?.forEach((type: string) => {
          if (type === 'address') {
            geolocation = {
              ...geolocation,
              custom_locations: [
                {
                  latitude: brand?.address?.lat,
                  longitude: brand?.address?.lng,
                  radius: diySettings?.maxAddressRadius || 50,
                },
              ],
            };
          } else {
            const field = getGeolocationFields(type);
            geolocation = {
              ...geolocation,
              [field]: [...(diySettings?.geolocation[field] || [])],
            };
          }
        });
      } else {
        geolocation = { countries: ['US'] };
      }

      if (diySettings?.excludedGeolocations) {
        targeting = {
          ...targeting,
          excluded_geo_locations: {
            ...diySettings?.excludedGeolocations,
          },
        };
      }

      targeting = {
        ...targeting,
        geo_locations: geolocation,
      };

      getAudienceSize(targeting, template?.adSetTemplate?.optimization_goal);
    }
  }, [tabLabel, fbAd, isTemplate, diySettings, template]);

  useEffect(() => {
    if (
      tabLabel === 'Conversion' &&
      ((!isTemplate &&
        creative &&
        fbAd?.campaign?.objective === Objectives.OUTCOME_LEADS) ||
        (isTemplate &&
          template &&
          template?.campaignTemplate?.objective === Objectives.OUTCOME_LEADS))
    ) {
      getFbLeadForm();
    }
  }, [tabLabel, creative, isTemplate, template, fbAd]);

  useEffect(() => {
    if (
      tabLabel === 'Notification' &&
      ((!isTemplate && creative) || (isTemplate && template))
    ) {
      getBrandLeadForm();
    }
  }, [tabLabel, creative, isTemplate, template]);

  useEffect(() => {
    if (tabLabel === 'Change Logs' && !isTemplate && brand) {
      getChangeLogs();
    }
  }, [tabLabel, isTemplate, brand]);

  const getFbCustomAudiences = async () => {
    try {
      setLoading(true);

      const response = await getCustomAudiences(
        brand?.__type === 'location' ? brand?.franchisor?._id : brand?._id,
      );

      setCustomAudiences(response.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getDiySetting = async () => {
    try {
      setLoading(true);

      const response = await getSingleDiySetting(brand?._id);

      setDiySettings(response.data);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getFbAd = async () => {
    try {
      setFbAdLoading(true);

      const response = await fetchSpecificFbAd(adId, brand?._id);

      setFbAd(response.data);

      const creative = await getSingleFbAdCreative(
        brand?._id,
        response?.data?.creative?.id,
      );

      setCreative(creative.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setFbAdLoading(false);
    }
  };

  const getFbAdset = async () => {
    try {
      setLoading(true);

      const response = await fetchSpecificFbAdset(fbAd?.adset_id, brand?._id);

      setFbAdset(response.data);
      getAudienceSize(response.data.targeting, response.data.optimization_goal);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getAudienceSize = async (targeting: any, optimizationGoal: string) => {
    try {
      setAudienceSizeLoading(true);

      const audienceSize = await getFbTargetingReachEstimate(
        brand?._id,
        targeting,
        optimizationGoal,
        !isTemplate,
        IS_DIY_ADZ ? null : getAccountId(),
      );

      setAudienceSize(audienceSize.data);
      setAudienceSizeError(null);
    } catch (error: any) {
      const err = error.response.data;
      setAudienceSizeError(err?.message);
    } finally {
      setAudienceSizeLoading(false);
    }
  };

  const getAccountId = () => {
    if (isTemplate) {
      return template?.accountId;
    }

    return fbAd?.account_id;
  };

  const getBrandAd = async () => {
    try {
      setLoading(true);
      const response = await getSingleBrandAd(adId, brand?._id);

      const campaigns = await fetchBrandCampaigns(
        brand?._id,
        adsProvider.FACEBOOK,
        true,
      );

      let adsTemp: any[] = [];
      campaigns.data.forEach((campaign: Campaign) => {
        campaign.ads.forEach((ad: CampaignAd) => {
          if (ad.adset_id === response.data.adset_id) {
            adsTemp = [...adsTemp, ad];
          }
        });
      });

      const adStatuses = adsTemp.map((ad: CampaignAd) => ad.status);

      const creditable = adStatuses.every(
        (status: string) =>
          [
            FbAdStatus.WITH_ISSUES,
            FbAdStatus.DISAPPROVED,
            FbAdStatus.ADSET_PAUSED,
            FbAdStatus.CAMPAIGN_PAUSED,
            FbAdStatus.PAUSED,
          ].includes(status) &&
          ![FbAdStatus.PROCESSING_PAYMENT, FbAdStatus.PAYMENT_FAILED].includes(
            status,
          ),
      );

      let temp: CampaignAd = { ...response.data, creditable };

      setAd(temp);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getTemplate = async () => {
    try {
      setPreviewLoading(true);
      const response = await fetchSingleCampaignTemplate(
        isTemplate ? templateId : ad?.template,
      );

      if (response.data?.media === 'video') {
        const video = await fetchSingleFbAdAccountVideo(
          brand?._id,
          response?.data?.creativeTemplate?.asset_feed_spec?.videos[0]
            ?.video_id,
        );

        setAdVideo(video.data);
      }

      setTemplate(response.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setPreviewLoading(false);
    }
  };

  const generatePreview = async () => {
    try {
      setPreviewLoading(true);

      const response = await generateAdsPreview(
        fbAd.creative.id,
        CreativeTemplate.MOBILE,
        brand?._id,
      );

      setFbPreview(response.data.body);
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setPreviewLoading(false);
    }
  };

  const getFbLeadForm = async () => {
    try {
      setLoading(true);

      const form = await fetchSpecificFacebookGenForms(
        (brand?.__type === 'location' ? brand?.franchisor : brand)?._id,
        (isTemplate ? template?.creativeTemplate : creative)?.asset_feed_spec
          ?.call_to_actions[0]?.value?.lead_gen_form_id,
      );

      setFbForm(form.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getBrandLeadForm = async () => {
    try {
      setLoading(true);

      const response = await getSingleLeadForms(
        null,
        (isTemplate ? template?.creativeTemplate : creative)?.asset_feed_spec
          ?.call_to_actions[0]?.value?.lead_gen_form_id,
        brand?._id,
      );

      if (response.data) {
        const notification = await getFormLeadNotification(
          response.data._id,
          brand?._id,
        );

        setNotification(notification.data);
      }

      setForm(response.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getChangeLogs = async () => {
    try {
      setLogsLoading(true);

      const levels =
        level === 'ad'
          ? 'ad,adset,campaign'
          : level === 'adset'
          ? 'campaign,adset'
          : 'campaign';

      const response = await getAdsChangeLogs({
        brandId: brand?._id,
        provider: 'facebook',
        campaignId,
        adsetId,
        adId,
        level: levels,
      });

      setLogs(response.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLogsLoading(false);
    }
  };

  const handleChangeTabValue = (event: SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const buildTabs = (objective: string) => {
    let tabs: string[] = [];

    const isAds = level === 'ad';

    if (isAds) {
      tabs = [...tabs, 'Preview', 'Targets'];
    }

    if (IS_DIY_ADZ && isAds) {
      tabs = [...tabs, 'Conversion'];
    }

    if (objective === Objectives.OUTCOME_LEADS && IS_DIY_ADZ && isAds) {
      tabs = [...tabs, 'Notification'];
    }

    if (!isTemplate) {
      tabs = [...tabs, 'Change Logs'];
    }

    return tabs;
  };

  const renderTabContent = (tab: string) => {
    if (tab === 'Preview') {
      return (
        <FacebookAdPreview
          fbAd={fbAd}
          previewIframe={fbPreview}
          template={template}
          diySettings={diySettings}
          brand={brand}
          adVideo={adVideo}
          loading={previewLoading || loading}
          isTemplate={isTemplate}
          fbAdLoading={fbAdLoading}
        />
      );
    }

    if (tab === 'Targets') {
      return (
        <FacebookAdTargets
          targeting={
            isTemplate ? template?.adSetTemplate?.targeting : fbAdset?.targeting
          }
          loading={loading}
          isTemplate={isTemplate}
          customAudiences={customAudiences}
          audienceSize={audienceSize}
          audienceSizeLoading={audienceSizeLoading}
          audienceSizeError={audienceSizeError}
        />
      );
    }

    if (tab === 'Conversion') {
      return (
        <FacebookConversionDetails
          leadForm={fbForm}
          loading={loading}
          objective={
            isTemplate
              ? template?.campaignTemplate?.objective
              : fbAd?.campaign?.objective
          }
          creative={isTemplate ? template?.creativeTemplate : creative}
        />
      );
    }

    if (tab === 'Notification') {
      return (
        <LeadNotificationDetails
          form={form}
          notification={notification}
          loading={loading}
        />
      );
    }

    if (tab === 'Change Logs') {
      return <CampaignLogs logs={logs} loading={logsLoading} />;
    }
  };

  const renderActions = () => {
    if (isTemplate) {
      if (isDiy) {
        return (
          <Grid item>
            {!fetchLoading ? (
              <PrimaryButton
                title="Select"
                type="button"
                handleOnClick={() => onSelect(template)}
                marginRight5
              />
            ) : null}
          </Grid>
        );
      } else {
        return (
          <>
            <Grid item>
              <PrimaryButton
                title="Edit"
                type="button"
                handleOnClick={() =>
                  navigate(`/templatez/edit/${template?._id}`)
                }
                marginRight5
                startIcon={<EditIcon />}
              />
            </Grid>

            <Grid item>
              <PrimaryButton
                title="Duplicate"
                type="button"
                handleOnClick={() =>
                  navigate(`/templatez/duplicate/${template?._id}`)
                }
                marginRight5
                startIcon={<ContentCopyIcon />}
              />
            </Grid>

            <Grid item>
              <PrimaryButton
                title={`${confirmArchive ? 'Really archive?' : 'Archive'}`}
                type="button"
                handleOnClick={() =>
                  confirmArchive ? onArchive() : setConfirmArchive(true)
                }
                startIcon={<ArchiveIcon />}
                loading={archiveLoading}
                theme={confirmArchive ? 'red' : DEFAULT_COLOR_THEME}
              />
            </Grid>
          </>
        );
      }
    }

    return (
      <>
        {!(ad?.status === FbAdStatus.WITH_ISSUES) ? (
          <Grid item>
            <PrimaryButton
              title="A/B Test"
              type="button"
              handleOnClick={() => navigate(`/adz/ab-test/${fbAd?.id}`)}
              marginRight5
              startIcon={<AddOutlinedIcon />}
              loading={stopLoading}
            />
          </Grid>
        ) : null}

        <Grid item>
          <PrimaryButton
            title="Edit"
            type="button"
            handleOnClick={() => setOpenConfirmModal(true)}
            marginRight5
            startIcon={<EditOutlinedIcon />}
            loading={stopLoading}
          />
        </Grid>

        {ad?.creditable && !ad?.refunded && brand?.allowSetupPayment ? (
          <Grid item>
            <PrimaryButton
              title={
                confirmCredit ? 'Really request credit?' : 'Request Credit'
              }
              type="button"
              handleOnClick={() =>
                confirmCredit
                  ? onStopAd(fbAd?.id, true)
                  : setConfirmCredit(true)
              }
              marginRight5
              startIcon={<GiReceiveMoney />}
              loading={stopLoading}
              theme={confirmCredit ? 'red' : DEFAULT_COLOR_THEME}
            />
          </Grid>
        ) : null}

        {[FbAdStatus.ACTIVE, FbAdStatus.IN_PROCESS].includes(ad?.status) ? (
          <Grid item>
            <PrimaryButton
              title={confirmStop ? 'Really stop?' : 'Stop Ads'}
              type="button"
              handleOnClick={() =>
                confirmStop ? onStopAd(fbAd?.id) : setConfirmStop(true)
              }
              marginRight5
              startIcon={<AiOutlineStop />}
              loading={stopLoading}
              theme={confirmStop ? 'red' : DEFAULT_COLOR_THEME}
            />
          </Grid>
        ) : null}
      </>
    );
  };

  return (
    <Box
      sx={{
        minWidth: xsOnly ? '100%' : '410px',
        maxWidth: xsOnly ? '100%' : '410px',
        padding: '20px',
      }}
    >
      {isMobile ? (
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <IconButton onClick={onClose} type="button">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      ) : null}

      <TabContext value={tabValue}>
        <TabList
          onChange={handleChangeTabValue}
          variant="scrollable"
          allowScrollButtonsMobile
          scrollButtons
          textColor="secondary"
          TabIndicatorProps={{ style: { backgroundColor: 'white' } }}
          TabScrollButtonProps={{
            style: { backgroundColor: DEFAULT_COLOR_THEME, color: 'white' },
          }}
          sx={{
            backgroundColor: DEFAULT_COLOR_THEME,
            '.MuiTab-root': {
              top: 5,
              color: 'white',
            },
            '.Mui-selected': {
              color: `${DEFAULT_COLOR_THEME} !important`,
              backgroundColor: 'white',
              top: 5,
              borderRadius: 2,
            },
            width: '100%',
          }}
        >
          {buildTabs(
            isTemplate
              ? template?.campaignTemplate?.objective
              : fbAd?.campaign?.objective,
          )?.map((tab: string, index: number) => (
            <Tab label={tab} value={`${index + 1}`} key={`ad-details-${tab}`} />
          ))}
        </TabList>

        {buildTabs(
          isTemplate
            ? template?.campaignTemplate?.objective
            : fbAd?.campaign?.objective,
        ).map((tab: string, index: number) => {
          return (
            <TabPanel
              value={`${index + 1}`}
              sx={{
                backgroundColor: '#d9d9d933',
                minHeight: `calc(100vh - ${withAction ? '125px' : '90px'})`,
                maxHeight: `calc(100vh - ${withAction ? '125px' : '90px'})`,
                overflowY: 'auto',
                overflowX: 'hidden',
                '&::-webkit-scrollbar': {
                  width: '10px',
                },
                '&::-webkit-scrollbar-track': {
                  borderTopRightRadius: '5px',
                  borderBottomRightRadius: '5px',
                },

                '&::-webkit-scrollbar-thumb': {
                  background: DEFAULT_COLOR_THEME,
                  borderRadius: '18px',

                  '&:hover': {
                    background: DEFAULT_COLOR_THEME,
                  },
                },
              }}
              key={`panel-${tab}`}
            >
              {renderTabContent(tab)}
            </TabPanel>
          );
        })}
      </TabContext>

      {withAction && !previewLoading && !loading && !fbAdLoading ? (
        <Box
          sx={{
            width: '100%',
            textAlign: 'center',
            position: 'sticky',
            bottom: 0,
          }}
        >
          <Grid
            container
            spacing={1}
            xs={12}
            sx={{ justifyContent: 'center', display: 'flex' }}
          >
            {renderActions()}
          </Grid>
        </Box>
      ) : null}
    </Box>
  );
};

export default FacebookAdDetails;
