import axios from 'axios';
import { apiBaseUrl } from './api';

export const fetchGoogleCustomers = async (refreshToken: string) => {
  const data = await axios.get(
    `${apiBaseUrl}/ads/google/customers?refreshToken=${refreshToken}`,
  );

  return data?.data;
};

export const fetchGoogleAdsAccount = async (
  brandId: string,
  refreshToken: string,
) => {
  const data = await axios.get(`${apiBaseUrl}/ads/google/accounts`, {
    params: {
      brandId,
      refreshToken,
    },
  });

  return data?.data;
};

export const syncCustomerIds = async (
  brandId: string,
  customerIds: string[],
) => {
  const data = await axios.post(`${apiBaseUrl}/ads/google/customers/sync`, {
    brandId,
    customerIds,
  });

  return data?.data;
};

export const fetchGoogleCampaigns = async (
  brandId: string,
  refreshToken: string,
) => {
  const data = await axios.get(
    `${apiBaseUrl}/ads/google/campaigns?brandId=${brandId}&refreshToken=${refreshToken}`,
  );

  return data?.data;
};

export const setGoogleKeywords = async (params: {
  brandId: string;
  matchType: string[];
  keyword: {
    match_type: string;
    text: string;
    resource_name: string;
    ad_group_resource_name: string;
    negative?: boolean | undefined;
  }[];
}) => {
  const newkeywords = await axios.post(
    `${apiBaseUrl}/report/brand/campaign/google/keywords`,
    {
      ...params,
    },
  );

  return newkeywords?.data;
};
