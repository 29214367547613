import {
  constantStringToHumanized,
  humanizeString,
} from '../../../utils/stringModifier';
import {
  Box,
  Tooltip,
  Typography,
  unstable_composeClasses as composeClasses,
} from '@mui/material';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { CampaignAd } from '../../../types';
import { Dispatch, SetStateAction } from 'react';
import { toCurrency } from '../../../utils/numberFormatter';
import { FbAdStatus } from '../../../utils/helpers/facebookAdsHelper';
import {
  DataGridPremiumProps,
  getDataGridUtilityClass,
  GridColDef,
  gridFilteredDescendantCountLookupSelector,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from '@mui/x-data-grid-premium';
import { defaultProps } from '../../../utils/helpers/TableHelpers';
import { arrayUniqueFilter } from '../../../utils/arrayFormatter';
import { dayJsInstance } from '../../../utils/dayjsHelper';

const isRejected = (status: string) => {
  return [
    FbAdStatus.WITH_ISSUES,
    FbAdStatus.DISAPPROVED,
    FbAdStatus.ADSET_PAUSED,
    FbAdStatus.CAMPAIGN_PAUSED,
    FbAdStatus.PAYMENT_FAILED,
    FbAdStatus.PAUSED,
  ].includes(status);
};

const isProcessing = (status: string) => {
  return [FbAdStatus.PROCESSING_PAYMENT, FbAdStatus.PENDING_REVIEW].includes(
    status,
  );
};

export const adsTableGroupingColumn = (
  isMobile: boolean,
  onOpenDetails: (id: string, level: string, objective: string) => void,
  expandedKeys: string[],
  setExpandedKeys: Dispatch<SetStateAction<string[]>>,
  rows: CampaignAd[],
) => {
  const gridGroupColumn: DataGridPremiumProps['groupingColDef'] = {
    ...defaultProps(isMobile, 600),
    headerName: 'Campaign',
    headerClassName: `${styles.header}`,
    sortable: true,
    renderCell: (params) => (
      <CustomGridGroupColDef
        {...params}
        onClick={onOpenDetails}
        expandedKeys={expandedKeys}
        setExpandedKeys={setExpandedKeys}
        data={rows}
      />
    ),
  };

  return gridGroupColumn;
};

export const adsTableColumns = (timezone: string) => {
  const columns: GridColDef[] = [
    {
      field: 'objective',
      headerClassName: styles.header,
      headerName: 'Objective',
      width: 150,
      groupingValueGetter: (_, row) => row.objective,
      renderCell: (params) => {
        const { objective, level, onOpenPreview, id } = params.row;

        return level === 'campaign' ? (
          <Tooltip
            title={
              <Typography variant="body2">
                {constantStringToHumanized(objective || '')}
              </Typography>
            }
            disableInteractive
          >
            <div
              className={styles.name}
              onClick={() => onOpenPreview(id, level, objective)}
            >
              {constantStringToHumanized(objective || '')}
            </div>
          </Tooltip>
        ) : (
          <div
            className={styles.name}
            onClick={() => onOpenPreview(id, level, objective)}
          />
        );
      },
    },
    {
      field: 'duration',
      headerClassName: styles.header,
      headerName: 'Duration',
      width: 200,
      groupingValueGetter: (_, row) => row.startTime,
      renderCell: (params) => {
        const { startTime, endTime, onOpenPreview, level, id, objective } =
          params.row;

        return level === 'adset' ? (
          <Tooltip
            title={
              <Typography variant="body2">
                {`${dayJsInstance
                  .unix(startTime)
                  .tz(timezone)
                  .format('MM-DD-YYYY')} - ${
                  endTime
                    ? dayJsInstance
                        .unix(endTime)
                        .tz(timezone)
                        .format('MM-DD-YYYY')
                    : 'On-going'
                }`}
              </Typography>
            }
            disableInteractive
          >
            <div
              onClick={() => onOpenPreview(id, level, objective)}
              className={styles.name}
            >
              {`${dayJsInstance
                .unix(startTime)
                .tz(timezone)
                .format('MM-DD-YYYY')} - ${
                endTime
                  ? dayJsInstance
                      .unix(endTime)
                      .tz(timezone)
                      .format('MM-DD-YYYY')
                  : 'On-going'
              }`}
            </div>
          </Tooltip>
        ) : (
          <div
            onClick={() => onOpenPreview(id, level, objective)}
            className={styles.name}
          />
        );
      },
    },
    {
      field: 'budget',
      headerClassName: styles.header,
      headerName: 'Budget',
      width: 100,
      groupingValueGetter: (_, row) => row.budget,
      renderCell: (params) => {
        const { onOpenPreview, budget, level, id, objective } = params.row;

        return level === 'adset' ? (
          <Tooltip
            title={
              <Typography variant="body2">
                {toCurrency('USD', budget)}
              </Typography>
            }
            disableInteractive
          >
            <div
              onClick={() => onOpenPreview(id, level, objective)}
              className={styles.name}
            >
              {level === 'adset' ? toCurrency('USD', budget) : ''}
            </div>
          </Tooltip>
        ) : (
          <div
            onClick={() => onOpenPreview(id, level, objective)}
            className={styles.name}
          />
        );
      },
    },
    {
      field: 'description',
      headerClassName: styles.header,
      headerName: 'Description',
      width: 200,
      groupingValueGetter: (_, row) => row.description,
      renderCell: (params) => {
        const { description, onOpenPreview, level, id, objective } = params.row;

        return level === 'ad' ? (
          <Tooltip
            title={<Typography variant="body2">{description}</Typography>}
            disableInteractive
          >
            <div
              className={styles.name}
              onClick={() => onOpenPreview(id, level, objective)}
            >
              {description}
            </div>
          </Tooltip>
        ) : (
          <div
            className={styles.name}
            onClick={() => onOpenPreview(id, level, objective)}
          />
        );
      },
    },
    {
      field: 'media',
      headerClassName: styles.header,
      headerName: 'Media',
      width: 100,
      groupingValueGetter: (_, row) => row.media,
      renderCell: (params) => {
        const { onOpenPreview, media, level, id, objective } = params.row;

        return level === 'ad' ? (
          <Tooltip
            title={
              <Typography variant="body2">{humanizeString(media)}</Typography>
            }
            disableInteractive
          >
            <div
              onClick={() => onOpenPreview(id, level, objective)}
              className={styles.name}
            >
              {humanizeString(media)}
            </div>
          </Tooltip>
        ) : (
          <div
            onClick={() => onOpenPreview(id, level, objective)}
            className={styles.name}
          />
        );
      },
    },
    {
      field: 'status',
      headerClassName: styles.header,
      headerName: 'Status',
      width: 100,
      renderCell: (params) => {
        const { status, onOpenPreview, isResumable, level, id, objective } =
          params.row;

        return (
          <Tooltip
            title={
              <Typography variant="body2">
                {isResumable
                  ? 'Paused for Budget'
                  : constantStringToHumanized(status || '')}
              </Typography>
            }
            disableInteractive
          >
            <div
              onClick={() => onOpenPreview(id, level, objective)}
              className={`${styles['name']} ${
                isProcessing(status)
                  ? styles['-processing']
                  : isRejected(status)
                  ? styles['-error']
                  : ''
              }`}
            >
              {isResumable
                ? 'Paused for Budget'
                : (constantStringToHumanized(status || '') as any)}
            </div>
          </Tooltip>
        );
      },
    },
  ];

  return columns;
};

type OwnerState = { classes: DataGridPremiumProps['classes'] };

const useUtilityClasses = (ownerState: OwnerState) => {
  const { classes } = ownerState;

  const slots = {
    root: ['treeDataGroupingCell'],
    toggle: ['treeDataGroupingCellToggle'],
  };

  return composeClasses(slots, getDataGridUtilityClass, classes);
};

export const CustomGridGroupColDef = (props: any) => {
  const {
    id,
    field,
    formattedValue,
    rowNode,
    offsetMultiplier = 2,
    onClick,
    row,
    expandedKeys,
    setExpandedKeys,
    data,
  } = props;

  const rootProps = useGridRootProps();
  const apiRef = useGridApiContext();
  const ownerState = { classes: rootProps.classes };
  const classes = useUtilityClasses(ownerState);
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector,
  );

  const filteredDescendantCount =
    filteredDescendantCountLookup[rowNode.id] ?? 0;

  const Icon = (rowNode as any).childrenExpanded
    ? rootProps.slots.treeDataCollapseIcon
    : rootProps.slots.treeDataExpandIcon;

  const setCollapseKeys = () => {
    let groups: string[] = [...expandedKeys];

    const existingKey = groups.find(
      (key: any) => key === row.group[row.group.length - 1],
    );

    if (existingKey) {
      groups = groups.filter((key: string) => key !== existingKey);
    } else {
      (data || [])?.forEach((report: any) => {
        if (report.group.includes(row.group[0])) {
          groups = [...groups, ...report.group];
        }
      });
    }

    const filteredKeys = arrayUniqueFilter(groups);
    setExpandedKeys(filteredKeys);
  };

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    apiRef.current.setRowChildrenExpansion(
      id,
      !(rowNode as any).childrenExpanded,
    );
    apiRef.current.setCellFocus(id, field);
    setCollapseKeys();
    event.stopPropagation();
  };

  const value =
    formattedValue === undefined
      ? (rowNode as any).groupingKey
      : formattedValue;

  const removeLastParenthesisContent = (str: string): string => {
    return str.replace(/\s*\([^)]*\)\s*$/, '');
  };

  return (
    <Box className={classes.root} sx={{ ml: rowNode.depth * offsetMultiplier }}>
      <div className={classes.toggle}>
        {filteredDescendantCount > 0 && (
          <rootProps.slots.baseIconButton
            size="small"
            onClick={handleOnClick}
            tabIndex={-1}
            aria-label={
              (rowNode as any).childrenExpanded
                ? apiRef.current.getLocaleText('treeDataCollapse')
                : apiRef.current.getLocaleText('treeDataExpand')
            }
            {...rootProps?.slotProps?.baseIconButton}
          >
            <Icon fontSize="inherit" />
          </rootProps.slots.baseIconButton>
        )}
      </div>

      <div
        className={styles.name}
        onClick={() => onClick(row.id, row.level, row.objective)}
      >
        <Tooltip
          title={
            <Typography variant="body2">
              {`${removeLastParenthesisContent(value)}`}
            </Typography>
          }
          disableInteractive
        >
          {removeLastParenthesisContent(value) as any}
        </Tooltip>
      </div>
    </Box>
  );
};
