import React, { useContext, useEffect, useState } from 'react';
import styles from '../../assets/styles/pages/Pages.module.scss';
import PageHeader from '../../components/PageHeader';
import { PrimaryButton } from '../../components/Buttons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Brand, CampaignAd } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBrandCampaignsNested, syncFbAds } from '../../services/ads';
import { adsProvider } from '../../utils/constants/facebookAds';
import { DrawerMenu } from '../../components/Menu';
import { ADMIN, SUPER_ADMIN } from '../../utils';
import { AuthContext } from '../../context';
import AdListTable from '../../components/Tables/AdsList/AdListTable';
import { stopFbAds } from '../../services/diy';
import { toggleAlert } from '../../redux/actions';
import PopupModal from '../../components/Modal';
import CreditPreview from '../../components/Modal/CreditPreview';
import FacebookAdDetails from '../../components/Details/Ads/FacebookAdDetails';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ModalHeader from '../../components/Modal/ModalHeader';
import BudgetPreview from '../../components/Modal/BudgetPreview';

const Adz: React.FC = () => {
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const [searchParams] = useSearchParams();
  const fromUpdateBudget = searchParams.get('fromUpdateBudget') === 'true';
  const thisMonthsBudget = parseFloat(
    searchParams.get('thisMonthsBudget') || '0',
  );
  const totalCredit = parseFloat(searchParams.get('creditAmount') || '0');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );
  const { state } = useContext(AuthContext);
  const isAdmin = state.role === ADMIN;
  const isSuperAdmin = state.role === SUPER_ADMIN;
  const capabilities = state.capabilities;
  const [stopLoading, setStopLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const [ads, setAds] = useState<CampaignAd[]>([]);
  const [confirmStop, setConfirmStop] = useState<boolean>(false);
  const [confirmCredit, setConfirmCredit] = useState<boolean>(false);
  const [openCreditPreview, setOpenCreditPreview] = useState<boolean>(false);
  const [creditedAmount, setCreditedAmount] = useState<number>(0);
  const [openBudgetPreview, setOpenBudgetPreview] = useState<boolean>(false);
  const [currentMonthsBudget, setCurrentMonthsBudget] = useState<number>(0);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [editFields, setEditFields] = useState<string[]>([]);
  const [level, setLevel] = useState<string>(null);
  const [campaignId, setCampaignId] = useState<string>(null);
  const [adsetId, setAdsetId] = useState<string>(null);
  const [adId, setAdId] = useState<string>(null);
  const [objective, setObjective] = useState<string>(null);

  useEffect(() => {
    if (location || brand) {
      const delayDebounceFn = setTimeout(() => {
        getCampaigns();
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [location, brand]);

  useEffect(() => {
    if (fromUpdateBudget) {
      setCurrentMonthsBudget(thisMonthsBudget);
      setOpenBudgetPreview(true);

      if (totalCredit > 0) {
        setCreditedAmount(totalCredit);
        setOpenCreditPreview(true);
      }
    }
  }, [fromUpdateBudget, totalCredit, thisMonthsBudget]);

  const getCampaigns = async () => {
    try {
      setFetchLoading(true);
      await syncFbAds((location || brand)?._id);

      const response = await fetchBrandCampaignsNested(
        (location || brand)?._id,
        adsProvider.FACEBOOK,
      );

      setAds(response.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const handleStopAds = async (adId: string, refund?: boolean) => {
    setStopLoading(true);
    try {
      const response = await stopFbAds(adId, (location || brand)?._id, refund);

      let temp: CampaignAd[] = [];
      const adsObj = ads.find((ad: CampaignAd) => ad.id === adId);
      ads.forEach((ad: CampaignAd) => {
        if (refund) {
          if (ad.adset_id === adsObj?.adset_id) {
            ad.refunded = true;
          }
        } else {
          if (ad.id === adId) {
            ad.status = 'PAUSED';
            ad.refunded = true;
          }
        }

        temp = [...temp, ad];
      });

      setAds(temp);
      setOpenPreview(false);

      if ((location || brand)?.allowSetupPayment) {
        setCreditedAmount(response?.creditedAmount || 0);
        if ((response?.creditedAmount || 0) > 0) setOpenCreditPreview(true);
      }
    } catch (error: any) {
      console.log(error);
      const err = error?.response?.data;
      if (err?.message) {
        dispatch(
          toggleAlert({ toggle: true, type: 'error', message: err?.message }),
        );
      }
    } finally {
      setStopLoading(false);
      if (refund) {
        setConfirmCredit(false);
      } else {
        setConfirmStop(false);
      }
    }
  };

  const handleOpenPreview = (id: string, level: string, objective: string) => {
    if (level === 'campaign') {
      setCampaignId(id);
      setAdsetId(null);
      setAdId(null);
    } else if (level === 'adset') {
      setAdsetId(id);
      setCampaignId(null);
      setAdId(null);
    } else {
      setAdId(id);
      setCampaignId(null);
      setAdsetId(null);
    }
    setLevel(level);
    setObjective(objective);
    setOpenPreview(true);
  };

  const handleClosePreview = () => {
    setCampaignId(null);
    setAdsetId(null);
    setAdId(null);
    setLevel(null);
    setObjective(null);
    setOpenPreview(false);
  };

  const handleCloseCreditPreview = () => {
    setOpenCreditPreview(false);
  };

  const handleCloseBudgetPreview = () => {
    setOpenBudgetPreview(false);
  };

  const handleConfirmChangeTemplate = () => {
    handleCloseConfirmModal();

    navigate(`/adz/edit/${adId}?fields=${editFields.join(',')}`);
  };

  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  const getCheckboxLabel = (field: string) => {
    if (field === 'audience') {
      return 'Audience (Lookalike, Detailed, Age, Gender)';
    }

    if (field === 'geographics') {
      return 'Geographics Targeting';
    }

    if (field === 'form') {
      return 'Form (Use a new form)';
    }

    if (field === 'website') {
      return 'Website URL (Ad landing page)';
    }

    if (field === 'template') {
      return 'Template (Use a new template or Use the current template with updated copy, images, or video)';
    }

    return 'Budget';
  };

  const buildEditFieldChecbox = (objective: string) => {
    let fields: string[] = ['budget', 'audience', 'geographics'];

    if (objective === 'Get More Leads') {
      fields = [...fields, 'form'];
    }

    if (objective === 'Get More Traffic') {
      fields = [...fields, 'website'];
    }

    fields = [...fields, 'template'];

    return fields;
  };

  return (
    <div className={styles.page}>
      <div className={styles.base}>
        <div className={styles.header}>
          <PageHeader title="Adz" />

          <div className={styles.controls}>
            <div className={styles.buttons}>
              {isAdmin || isSuperAdmin || capabilities?.createAds ? (
                <PrimaryButton
                  title="Create Adz"
                  type="button"
                  handleOnClick={() => navigate('/adz/diy')}
                />
              ) : null}
            </div>
          </div>
        </div>

        <div>
          <AdListTable
            ads={ads}
            loading={fetchLoading}
            onOpenPreview={handleOpenPreview}
          />
        </div>
      </div>

      <PopupModal
        open={openCreditPreview}
        handleClose={handleCloseCreditPreview}
      >
        <CreditPreview amount={creditedAmount} />
      </PopupModal>

      <PopupModal
        open={openBudgetPreview}
        handleClose={handleCloseBudgetPreview}
      >
        <BudgetPreview amount={currentMonthsBudget} />
      </PopupModal>

      <PopupModal open={openConfirmModal} handleClose={handleCloseConfirmModal}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ModalHeader title="Edit Ad" />
          </Grid>

          <Grid item xs={12} sx={{ flexDirection: 'column' }}>
            <Typography variant="body2" fontWeight="bold">
              Select the type of changes to make:
            </Typography>

            {buildEditFieldChecbox(objective).map((editField: string) => {
              const selected = editFields.includes(editField);

              return (
                <div>
                  <FormControlLabel
                    key={editField}
                    control={
                      <Checkbox size="small" defaultChecked={selected} />
                    }
                    name={editField}
                    label={getCheckboxLabel(editField)}
                    value={selected}
                    onChange={(e: any) => {
                      let fields: string[] = [...editFields];
                      if (!e.target.checked) {
                        fields = fields.filter(
                          (field: string) => field !== editField,
                        );
                      } else {
                        fields = [...fields, editField];
                      }

                      setEditFields(fields);
                    }}
                  />
                </div>
              );
            })}
          </Grid>

          <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
            <PrimaryButton
              title="Proceed"
              handleOnClick={handleConfirmChangeTemplate}
              type="button"
            />
          </Grid>
        </Grid>
      </PopupModal>

      <DrawerMenu
        anchor="right"
        open={openPreview}
        onClose={handleClosePreview}
      >
        <FacebookAdDetails
          brand={location || brand}
          adId={adId}
          withAction={level === 'ad'}
          stopLoading={stopLoading}
          setOpenConfirmModal={setOpenConfirmModal}
          confirmCredit={confirmCredit}
          onStopAd={handleStopAds}
          setConfirmCredit={setConfirmCredit}
          confirmStop={confirmStop}
          setConfirmStop={setConfirmStop}
          onClose={handleClosePreview}
          isMobile={xsOnly}
          campaignId={campaignId}
          adsetId={adsetId}
          level={level}
        />
      </DrawerMenu>
    </div>
  );
};

export default Adz;
